import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './locales/en/en.json';
import beTranslations from './locales/be/be.json';
import ruTranslations from './locales/ru/ru.json';
import chiTranslations from './locales/chi/chi.json';

const savedLanguage = localStorage.getItem('language');
i18n
  .use(initReactI18next)
  .init({
    resources: {
      EN: {
        translation: enTranslations,
      },
      BE: {
        translation: beTranslations,
      },
      RU: {
        translation: ruTranslations,
      },
      中文: {
        translation: chiTranslations,
      },
    },
    lng: savedLanguage || 'EN', 
    fallbackLng: 'EN', 
    interpolation: {
      escapeValue: false, 
    },
  });

export default i18n;