import { NavigationBar } from '../../Components/NavigationBar/NavigationBar'
import s from './Bots.module.scss'

import { BotsItem } from '../../Components/BotsItem/BotsItem'
import { botsArray, botsArrayWithRole } from './mock'
import { useTranslation } from 'react-i18next'
import { OtherPlatforms } from '../../Components/OtherPlatforms/OtherPlatforms'
import { Footer } from '../../Components/Footer/Footer'
import suggestBot from '../../assets/suggestBots.png'

export const Bots = () => {
    const { t } = useTranslation()

    const itemsWithRole = botsArrayWithRole.map((e, index) => <BotsItem key={index} src={e.src}
        name={t(`name.${e.name}`)} dev={t(`dev.${e.dev}`)} link={e.link} />)
    const items = botsArray.map((e, index) => <BotsItem key={index} src={e.src}
        name={t(`name.${e.name}`)} dev={t(`dev.${e.dev}`)} link={e.link} />)

    window.scrollTo({ top: 0, behavior: 'smooth' })
    return (
        <div className={s.wrapper}>
            <div className={s.bots_container}>
                <NavigationBar mainPage={t('NavigationMain')} pageName={t('TelegramBotsButton')} />
                <div className={s.bots_content}>
                    <h1>
                        {t('BotsTitleWithRole')}
                    </h1>
                    <div className={s.items}>
                        {itemsWithRole}
                        <BotsItem 
                            src={suggestBot}
                            name={t(`suggestBot`)} 
                            link={'https://docs.google.com/forms/d/1Xey4v8z7X2xxppEWpjg6uYlKC3YILYBBrwFpumd8zXs/edit'} />
                    </div>
                </div>
                <div className={s.bots_content}>
                    <h1>
                        {t('BotsTitle')}
                    </h1>
                    <div className={s.items}>
                        {items}
                    </div>
                </div>
                <OtherPlatforms activePlatform={'bots'} />
                <Footer />
            </div>
        </div>

    )
}
