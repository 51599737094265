import { useTranslation } from 'react-i18next'
import { NavigationBar } from '../../Components/NavigationBar/NavigationBar'
import s from './Contacts.module.scss'
import { Footer } from '../../Components/Footer/Footer'


export const Contacts = () => {

    const { t } = useTranslation()
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return (
        <section className={s.wrapper}>
            <div className={s.contacts_container}>
                <NavigationBar mainPage={t('NavigationMain')} pageName={t('contacts')} />
                <div className={s.contacts_content}>
                    <div className={s.title}>
                        {t('contacts')}
                    </div>
                    <div className={s.text}>
                        <div className={s.text_item}>
                            {t('contactsText')}
                        </div>
                        <div className={s.information_box}>
                            <div className={s.box_title}>
                                {t('eMail')}
                            </div>
                            <a href="mailto:hello@asistent.io" target="_blank" rel="noreferrer">
                                hello@asistent.io
                            </a>
                        </div>
                        <div className={s.information_box}>
                            <div className={s.box_title}>
                                {t('phonesTitle')}
                            </div>
                            <div>
                                <a href="tel:+447700164788">+447700164788</a>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </section>
    )
}