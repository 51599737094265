import { useEffect, useRef, useState } from 'react';
import i18n from '../../../i18n';
import { ReactComponent as Language } from '../../../assets/language.svg'
import s from './SelectLanguage.module.scss'
import { useTranslation } from 'react-i18next';

export const SelectLanguage = () => {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'EN')
    const optionBodyRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()
   
    const changeLanguage = (e) => {
        i18n.changeLanguage(e);
        setLanguage(e)
    }

    const closeOptionBody = (event) => {
        if (optionBodyRef.current && !optionBodyRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        localStorage.setItem('language', language)
        document.addEventListener('click', closeOptionBody)
        document.title = t('mainTitle')
        return () => {
            document.removeEventListener('click', closeOptionBody)
        };
    }, [language, t]);

    return (
        <div
            ref={optionBodyRef}
            onClick={() => setIsOpen(!isOpen)}
            className={s.languages_container}>
            <div className={s.selected_language}>
                <Language />
            </div>

            {isOpen ?
                <div className={s.languages}>
                    <div className={language === 'BE' ? s.lan_item : null} onClick={() => changeLanguage('BE')}>BE</div>
                    <div className={language === 'EN' ? s.lan_item : null} onClick={() => changeLanguage('EN')}>EN</div>
                    <div className={language === 'RU' ? s.lan_item : null} onClick={() => changeLanguage('RU')}>RU</div>
                    <div className={language === '中文' ? s.lan_item : null} onClick={() => changeLanguage('中文')}>中文</div>
                </div> :
                null}
        </div>
    )
}